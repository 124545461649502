$(function () {
    // console.log("JS Loaded 🚀");

    // imagelefttextright
    $(".image-grid-stacked .col-6 img:first-child").addClass("mb-4");

    //masonry
    var $grid = $('.grid').imagesLoaded(function () {
        // init Masonry after all images have loaded
        $('.grid').masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.grid-item',
            // use element for option
            columnWidth: '.grid-sizer',
            // percentPosition: true,
            gutter: 25,
            fitWidth: true
        })
    });

    $('.gallery').each(function () { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled: true
            },
            mainClass: 'mfp-with-zoom', // this class is for CSS animation below

            zoom: {
                enabled: true, // By default it's false, so don't forget to enable it

                duration: 300, // duration of the effect, in milliseconds
                easing: 'ease-in-out', // CSS transition easing function

                // The "opener" function should return the element from which popup will be zoomed in
                // and to which popup will be scaled down
                // By defailt it looks for an image tag:
                opener: function (openerElement) {
                    // openerElement is the element on which popup was initialized, in this case its <a> tag
                    // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            }

        });
    });

    //Booking page
    var invalidDates = [];
    $('.invalid-dates li').each(function () {
        var dateRangeString = $(this).text().replace(/[\[\]']+/g, ''); // remove brackets and quotes
        var dateRange = dateRangeString.split(','); // split into start and end dates
        var startDate = moment(dateRange[0]);
        var endDate = moment(dateRange[1]);
        while (startDate.isSameOrBefore(endDate)) { // loop over the date range
            var dateString = startDate.format('DD-MM-YYYY');
            invalidDates.push(dateString);
            startDate.add(1, 'days');
        }
    });

    //get tomorrow's date
    var AddOneDay = moment().add(1, 'days');
    // format tomorrow's date
    var tomorrow = AddOneDay.format("DD/MM/YYYY");
    $('input[name="dates"]').daterangepicker({
        "autoUpdateInput": false,
        "opens": "left",
        "minDate": tomorrow,
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Apply",
            "cancelLabel": 'Clear',
            "fromLabel": "From",
            "toLabel": "To",
            "customRangeLabel": "Custom",
            "weekLabel": "W",
            "daysOfWeek": [
                "Su",
                "Mo",
                "Tu",
                "We",
                "Th",
                "Fr",
                "Sa"
            ],
            "monthNames": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            "firstDay": 1
        },
        isInvalidDate: function (date) {
            var dateString = date.format('DD-MM-YYYY');
            return invalidDates.indexOf(dateString) != -1;
        }
    });

    $('input[name="dates"]').on('apply.daterangepicker', function (ev, picker) {
        $(this).attr("value", picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    });

    $('input[name="dates"]').on('cancel.daterangepicker', function (ev, picker) {
        $(this).attr("value", '');
    });

    

    // BEGIN - Star Rating

    var $ratingContainer = $("#id_rating"),
        $ratingInput = $("#id_rating input"),
        $ratingLabel = $("#id_rating label"),
        $ratingDiv = $("#id_rating div"),
        $this = $(this),
        $detachedElement = $ratingLabel.detach();

    $ratingContainer.addClass("rating-form");
    $ratingContainer.append($detachedElement);
    $ratingDiv.remove();
    $ratingLabel.contents().filter(function () { return (this.nodeType == 3); }).remove();
    $("#id_rating label:nth-child(1)").append('<span class="icon">★</span>');
    $("#id_rating label:nth-child(2)").append('<span class="icon">★</span><span class="icon">★</span>');
    $("#id_rating label:nth-child(3)").append('<span class="icon">★</span><span class="icon">★</span><span class="icon">★</span>');
    $("#id_rating label:nth-child(4)").append('<span class="icon">★</span><span class="icon">★</span><span class="icon">★</span><span class="icon">★</span>');
    $("#id_rating label:nth-child(5)").append('<span class="icon">★</span><span class="icon">★</span><span class="icon">★</span><span class="icon">★</span><span class="icon">★</span>');

    // END - Star Rating







});

